import apiClient from '@/helpers/axios.js';
import { store } from '../store/index';

export const paymentService = {
	createPaymentIntent,
};

/**
 * Created a payment-intent (stripe) for the provided trip items. Used to generate the payment form on the frontend.
 *
 * @param {number} tripID the ID for the trip
 * @param {object} tripItems an object array with the IDs for the tripItems, and the amounts. E.g: {0:{id: 1943, amount: 100}, 1:{id:1953, amount:150}}
 * @param {return} return returns a promise containing the payment intent
*/
function createPaymentIntent(tripID, tripItems, currency = "GBP") {
	var data = { 
		trip_id: tripID, 
		currency: currency,
		trip_items: tripItems, 
	};
	
	return apiClient.post('/create_payment_intent', data,
		{ headers: store.getters['account/getAuthHeader']}
	);
}
